import React from "react";
import RequestButton from "../RequestButton";

const WellWomanExamInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Well Woman Exam Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What is a well woman exam?</p>
        <p className="text-md text-gray-600 mb-12">
          A women’s wellness exam, or checkup, includes health screenings and
          medical care for gynecological conditions and other diseases specific
          to women’s needs. Scheduling annual wellness exams, even if you don’t
          experience unusual symptoms, allows your primary care specialist to
          detect and treat health issues before they become problematic.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are common women’s health concerns?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Women are at risk of specific health problems, which your provider
          addresses during wellness visits. Examples include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Breast cancer</li>
          <li>Cervical cancer</li>
          <li>Osteoporosis</li>
          <li>Menopause symptoms</li>
          <li>Infertility</li>
          <li>Gynecological problems</li>
          <li>Depression</li>
          <li>Heart disease</li>
          <li>Diabetes</li>
          <li>Obesity</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          During wellness checkups your doctor can screen you for these and
          other disease risk factors, such as high blood sugar, high blood
          pressure, and high cholesterol.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What happens during well woman exams?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Women’s wellness visits consist of several key components. When you
          arrive at your checkup, your primary care specialist evaluates your
          blood pressure and other vital signs, weighs you, and discusses your
          medical history. They ask about your menstrual period and if you’ve
          recently experienced any usual symptoms.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Your doctor completes a comprehensive physical exam, including breast
          and pelvic exams. During a Pap test, your doctor inserts a speculum
          into your vagina and scrapes cells from your cervix to screen for
          signs of cervical cancer.
        </p>
        <p className="text-md text-gray-600 mb-12">
          They might recommend you complete blood tests, urine tests, heart
          function tests, bone density screenings, a mammogram, ultrasound, or
          other imaging procedures to screen for diseases, hormone imbalance,
          urinary problems, and more.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          Which treatments are available?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Based on the results of your physical exam, diagnostic tests, disease
          risk factors, and your lifestyle and preferences, your provider might
          recommend the following treatments:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Diet and exercise changes</li>
          <li>Other lifestyle habit changes</li>
          <li>Counseling and education</li>
          <li>Dietary supplements</li>
          <li>Medications</li>
          <li>Hormone replacement therapy</li>
          <li>Vaccinations</li>
          <li>Birth control</li>
          <li>Further diagnostic testing</li>
          <li>Medical weight loss</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          If you require additional diagnostic tests or a surgical procedure,
          your doctor can refer you to a highly qualified women’s health
          specialist in your area.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Take charge of your health to prevent or treat women’s health problems
          before they become serious. Schedule well woman exams yearly or more
          often if your provider recommends it. Call Sunstate Medical Associates
          today or book an appointment online.
        </p>

        <RequestButton />
      </div>
    </div>
  );
};

export default WellWomanExamInfo;
