import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import WellWomanExamInfo from "../../components/services/WellWomanExamInfo";

const WellWomanExam = () => {
  return (
    <Layout>
      <PageHeader text="Well Woman Exam" />
      <ServicesHeader>
        To maintain optimal health and wellness, attending routine well woman
        exams at Sunstate Medical Associates in Lake Mary, Florida, is
        important. The expert internal medicine team offers a full scope of
        women’s wellness services to prevent health problems or detect them
        before complications develop. Schedule your next wellness visit by phone
        or book online today.
      </ServicesHeader>
      <WellWomanExamInfo />
      <Conditions />
    </Layout>
  );
};

export default WellWomanExam;
